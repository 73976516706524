import { DeviceInfoContext } from 'components/shared/DeviceInfo';
import { LocaleContext } from 'components/shared/Locale';
import React, { PureComponent } from 'react';
import styles from 'components/home/Collaboration.module.scss';
import AnimationScrollSpy from 'components/shared/AnimationScrollSpy';
import { ROUTE_FULL_PATH } from 'configs';
import PropTypes from 'prop-types';
import LocaleImage from 'components/shared/LocaleImage';
import Link from 'next/link';

class Collaboration extends PureComponent {
  renderPanel = ({ key, title, description, image, imageAlt }) => {
    return (
      <div className={styles.panel} key={key}>
        <div className={styles.panelImage}>
          <LocaleImage src={image} alt={imageAlt} />
        </div>
        <div className={styles.panelTitle}>{title}</div>
        <div className={styles.panelDescription}>{description}</div>
      </div>
    );
  };

  render() {
    const { setRef, isEnter } = this.props;
    const { context } = this;

    const innerCx = [styles.inner];
    if (isEnter) {
      innerCx.push(styles.innerEnter);
    }

    return (
      <LocaleContext.Consumer>
        {({ locale }) => (
          <div className={styles.wrap}>
            <div className={innerCx.join(' ')} ref={setRef}>
              <div className={styles.content}>
                <h2 className={styles.title}>
                  {locale('website_homepage_section_a')}
                </h2>
                <div className={styles.subtitle}>
                  {locale('website_homepage_section_a_intro')}
                </div>
              </div>

              <div className={styles.panels}>
                {this.renderPanel({
                  key: 1,
                  title: locale('website_homepage_section_a_cross_platform'),
                  description: locale(
                    'website_homepage_section_a_cross_platform_intro'
                  ),
                  image: context.isMobile
                    ? 'st-home-ic-platform-mobile.png'
                    : 'st-home-ic-platform.png',
                  imageAlt: locale(
                    'website_homepage_section_a_cross_platform_alt'
                  ),
                })}
                {this.renderPanel({
                  key: 2,
                  title: locale('website_homepage_section_a_comm'),
                  description: locale('website_homepage_section_a_comm_intro'),
                  image: context.isMobile
                    ? 'st-home-ic-communication-mobile.png'
                    : 'st-home-ic-communication.png',
                  imageAlt: locale('website_homepage_section_a_comm_alt'),
                })}
                {this.renderPanel({
                  key: 3,
                  title: locale('website_homepage_section_a_collab'),
                  description: locale(
                    'website_homepage_section_a_collab_intro'
                  ),
                  image: context.isMobile
                    ? 'st-home-ic-collaboration-mobile.png'
                    : 'st-home-ic-collaboration.png',
                  imageAlt: locale('website_homepage_section_a_collab_alt'),
                })}
                {this.renderPanel({
                  key: 4,
                  title: locale('website_homepage_section_a_mgmt'),
                  description: locale('website_homepage_section_a_mgmt_intro'),
                  image: context.isMobile
                    ? 'st-home-ic-tools-mobile.png'
                    : 'st-home-ic-tools.png',
                  imageAlt: locale('website_homepage_section_a_mgmt_alt'),
                })}
              </div>

              <Link href={`${ROUTE_FULL_PATH.FEATURES}/communication`}>
                <a className={styles.details}>
                  {locale('website_homepage_section_a_details')}
                </a>
              </Link>

              <div className={styles.phoneIcon} />
              <div className={styles.picIcon} />
              <div className={styles.messageIcon} />
              <div className={styles.dateIcon} />
              <div className={styles.safeIcon} />
              <div className={styles.chatIcon} />
            </div>
          </div>
        )}
      </LocaleContext.Consumer>
    );
  }
}

Collaboration.contextType = DeviceInfoContext;

Collaboration.propTypes = {
  setRef: PropTypes.func.isRequired,
  isEnter: PropTypes.bool,
};

export default AnimationScrollSpy(Collaboration);
