import React from 'react';
import { GTagScrollTracking } from '../../../utils/ga';
import useCustomScrollPosition from '../../../hooks/useCustomScrollPosition';

const defaultScrollPercentages = [25, 50, 75, 100];

const ScrollTracking = (
  WrappedComponent,
  scrollPercentages = defaultScrollPercentages
) => {
  const passedScrollPercentage = {};
  scrollPercentages.forEach((percentage) => {
    passedScrollPercentage[percentage] = false;
  });

  const ScrollTrackingComponent = (props) => {
    useCustomScrollPosition(
      ({ currPos }) => {
        const { scrollHeight } = document.scrollingElement;
        const { innerHeight } = window;
        const scrollBottomPos = Math.round(Math.abs(currPos.y)) + innerHeight;
        const scrollBottomPercentage = Math.round(
          (scrollBottomPos / scrollHeight) * 100
        );

        scrollPercentages.forEach((percentage) => {
          if (
            scrollBottomPercentage >= percentage &&
            !passedScrollPercentage[percentage]
          ) {
            passedScrollPercentage[percentage] = true;
            GTagScrollTracking.scrollVertically(percentage);
          }
        });
      },
      [],
      false,
      false,
      200
    );

    return <WrappedComponent {...props} />;
  };

  return ScrollTrackingComponent;
};

ScrollTracking.propTypes = {};

export default ScrollTracking;
