import React, { PureComponent } from 'react';

const delay = 5000;

const SwitchScreens = (WrappedComponent, screens) => {
  return class SplitScreen extends PureComponent {
    state = {
      showing: 0,
      hidePanel: false,
    };

    timeout = null;

    componentDidMount() {
      this.timeout = setTimeout(() => this.nextState(), delay);
    }

    componentWillUnmount() {
      clearTimeout(this.timeout);
    }

    setShowing(showing) {
      this.setState({ hidePanel: true, showing });
      setTimeout(() => {
        this.setState({ hidePanel: false });
      }, 100);
    }

    nextState() {
      const { showing } = this.state;
      this.setShowing((showing + 1) % screens);
      this.timeout = setTimeout(() => this.nextState(), delay);
    }

    fixScreen(showing) {
      if (showing === -1) {
        // return to normal auto scroll
        if (!this.timeout) {
          this.timeout = setTimeout(() => this.nextState(), delay);
        }
      } else {
        if (this.timeout) {
          clearTimeout(this.timeout);
          this.timeout = null;
        }
        this.setShowing(showing);
      }
    }

    render() {
      const { showing, hidePanel } = this.state;
      return (
        <WrappedComponent
          showing={showing}
          hidePanel={hidePanel}
          fixScreen={(showing) => this.fixScreen(showing)}
          setShowing={(showing) => this.setShowing(showing)}
          {...this.props}
        />
      );
    }
  };
};

export default SwitchScreens;
