import { LocaleContext } from 'components/shared/Locale';
import React, { PureComponent } from 'react';
import styles from 'components/home/Security.module.scss';
import AnimationScrollSpy from 'components/shared/AnimationScrollSpy';
import LocaleImage from 'components/shared/LocaleImage';

class Security extends PureComponent {
  renderItem = ({ title, desc, image, imageAlt }, index) => {
    const key = index + 1;
    return (
      <div className={styles.item} key={key}>
        <div className={styles.itemThumb}>
          <LocaleImage src={image} alt={imageAlt} />
        </div>
        <div className={styles.itemTitle}>{title}</div>
        <div className={styles.itemDesc}>
          {desc.map((d, didx) => (
            <div key={didx}>{d}</div>
          ))}
        </div>
      </div>
    );
  };

  render() {
    const { setRef, isEnter } = this.props;

    const innerCx = [styles.inner];
    if (isEnter) {
      innerCx.push(styles.innerEnter);
    }

    return (
      <LocaleContext.Consumer>
        {({ locale }) => {
          const items = [
            {
              title: locale('website_homepage_section_c_server_title'),
              desc: [locale('website_homepage_section_c_no_msg_server')],
              image: 'st-home-ic-security-server.png',
              imageAlt: locale('website_homepage_section_c_server_alt'),
            },
            {
              title: locale('website_homepage_section_c_whisper_title'),
              desc: [locale('website_homepage_section_c_whisper')],
              image: 'st-home-ic-security-whisper.png',
              imageAlt: locale('website_homepage_section_c_whisper_alt'),
            },
          ];

          return (
            <div className={styles.wrap}>
              <div className={innerCx.join(' ')} ref={setRef}>
                <div className={styles.content}>
                  <h2 className={styles.title}>
                    {locale('website_homepage_section_c')}
                  </h2>
                </div>
                <div className={styles.items}>{items.map(this.renderItem)}</div>
                <div className={styles.lockIcon} />
                <div className={styles.safeIcon} />
              </div>
            </div>
          );
        }}
      </LocaleContext.Consumer>
    );
  }
}

Security.propTypes = {
  ...AnimationScrollSpy.propTypes,
};

export default AnimationScrollSpy(Security);
