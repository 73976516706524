import { useLocaleContext } from 'components/shared/Locale';
import { useDeviceInfoContext } from 'components/shared/DeviceInfo';
import React from 'react';
import PropTypes from 'prop-types';
import { getImageSrc } from 'components/shared/LocaleImage/utils';
import Image from 'next/image';
import mmsLoader from '../../../../tools/scripts/image/loader';

// Based on the LocaleImage component
const ImageBg = (props) => {
  const { className, imageClassName, src } = props;
  const { language } = useLocaleContext();
  const { isAcceptWebp } = useDeviceInfoContext();

  return (
    <div className={className}>
      <Image
        className={imageClassName}
        loader={(loaderProps) => mmsLoader({ isAcceptWebp, ...loaderProps })}
        src={getImageSrc(src, language)}
        quality={100}
        layout="fill"
      />
    </div>
  );
};

ImageBg.propTypes = {
  className: PropTypes.string,
  imageClassName: PropTypes.string,
  src: PropTypes.string.isRequired,
};

export default ImageBg;
