import { useDeviceInfoContext } from 'components/shared/DeviceInfo';
import { useLocaleContext } from 'components/shared/Locale';
import React, { useCallback, useMemo, useState } from 'react';
import cx from 'classnames';
import styles from 'components/home/FocusOnPeople.module.scss';
import AnimationScrollSpy from 'components/shared/AnimationScrollSpy';
import SwitchScreens from 'components/shared/SwitchScreens';
import PropTypes from 'prop-types';
import Collapse from '@kunukn/react-collapse';
import LocaleImage from 'components/shared/LocaleImage';

const usePanels = () => {
  const { locale } = useLocaleContext();

  const panels = useMemo(
    () => [
      {
        title: locale('website_homepage_section_b_attendance'),
        detail: locale('website_homepage_section_b_attendance_intro_up'),
        picture: 'st-home-img-org-attendance-v2.png',
        pictureAlt: locale('website_homepage_section_b_attendance_alt'),
        icon: 'icon-attendance.png',
        iconAlt: locale('website_homepage_section_b_attendance_icon_alt'),
      },
      {
        title: locale('website_homepage_section_b_leave'),
        detail: locale('website_homepage_section_b_leave_up'),
        picture: 'st-home-img-org-leave-v2.png',
        pictureAlt: locale('website_homepage_section_b_leave_alt'),
        icon: 'icon-leave-up.png',
        iconAlt: locale('website_homepage_section_b_leave_icon_alt'),
      },
      {
        title: locale('website_homepage_section_b_claim'),
        detail: locale('website_homepage_section_b_claim_up'),
        picture: 'st-home-img-org-claim-v2.png',
        pictureAlt: locale('website_homepage_section_b_claim_alt'),
        icon: 'icon-claim-management.png',
        iconAlt: locale('website_homepage_section_b_claim_icon_alt'),
      },
      {
        title: locale('website_homepage_section_b_approval'),
        detail: locale('website_homepage_section_b_approval_up'),
        picture: 'st-home-img-org-approval-v2.png',
        pictureAlt: locale('website_homepage_section_b_approval_alt'),
        icon: 'icon-approval-up.png',
        iconAlt: locale('website_homepage_section_b_approval_icon_alt'),
      },
      {
        title: locale('website_homepage_section_b_checkin'),
        detail: locale('website_homepage_section_b_checkin_up'),
        picture: 'st-home-img-org-checkin-v2.png',
        pictureAlt: locale('website_homepage_section_b_checkin_alt'),
        icon: 'icon-checkin-up.png',
        iconAlt: locale('website_homepage_section_b_checkin_icon_alt'),
      },
    ],
    []
  );

  return { panels };
};

const Panel = ({ panel }) => (
  <>
    <div className={cx(styles.panelContentPic)}>
      <LocaleImage src={panel.picture} alt={panel.pictureAlt} />
    </div>
    <div className={styles.panelContentTitle}>{panel.title}</div>
    <div className={styles.panelContentDetail}>{panel.detail}</div>
  </>
);

Panel.propTypes = {
  panel: PropTypes.object.isRequired,
};

const MobileFocusOnPeople = () => {
  const { panels } = usePanels();
  const [activePanel, setActivePanel] = useState(null);

  const togglePanel = (id) => {
    if (activePanel === id) {
      setActivePanel(null);
    } else {
      setActivePanel(id);
    }
  };

  return (
    <div className={styles.focusOnPeopleInnerContent}>
      <div className={styles.listPanels}>
        {panels.map(({ title, icon, iconAlt }, id) => {
          const isActive = activePanel === id;

          return (
            <div key={id} className={styles.panel}>
              <div className={styles.panelText} onClick={() => togglePanel(id)}>
                <div className={cx(styles.panelIcon)}>
                  <LocaleImage src={icon} alt={iconAlt} />
                </div>
                <span>{title}</span>
                <div
                  className={
                    isActive ? styles.panelArrowUp : styles.panelArrowDown
                  }
                />
              </div>

              <Collapse isOpen={isActive}>
                {/* Check isActive to optimize first loading page */}
                {isActive && <Panel panel={panels[id]} />}
              </Collapse>
            </div>
          );
        })}
      </div>
    </div>
  );
};

const DesktopFocusOnPeople = SwitchScreens(({ showing, fixScreen }) => {
  const { panels } = usePanels();

  const getPanelClassName = useCallback(
    (id) => cx(styles.panel, id === showing ? styles.selectedPanel : ''),
    [showing]
  );

  return (
    <div className={styles.focusOnPeopleInnerContent}>
      <div className={styles.listPanels}>
        {panels.map(({ title, icon, iconAlt }, id) => (
          <div
            key={id}
            className={getPanelClassName(id)}
            onMouseEnter={() => fixScreen(id)}
            onMouseLeave={() => fixScreen(-1)}
          >
            <div className={styles.panelText}>
              <div className={cx(styles.panelIcon)}>
                <LocaleImage src={icon} alt={iconAlt} />
              </div>
              <span>{title}</span>
            </div>
          </div>
        ))}
      </div>

      <div
        className={styles.panelContent}
        onMouseEnter={() => fixScreen(showing)}
        onMouseLeave={() => fixScreen(-1)}
      >
        <Panel panel={panels[showing]} />
      </div>
    </div>
  );
}, 5);

DesktopFocusOnPeople.propTypes = {
  isEnter: PropTypes.bool,
};

const FocusOnPeople = ({ setRef, isEnter }) => {
  const { locale } = useLocaleContext();
  const { isMobile } = useDeviceInfoContext();

  return (
    <div className={styles.focusOnPeopleWrapper}>
      <div
        className={cx(
          styles.focusOnPeopleInner,
          isEnter ? styles.focusOnPeopleInnerEnter : ''
        )}
        ref={setRef}
      >
        <div className={styles.focusOnPeopleContent}>
          <h2 className={styles.title}>
            {locale('website_homepage_focus_on_people')}
          </h2>

          {!isMobile ? <DesktopFocusOnPeople /> : <MobileFocusOnPeople />}
        </div>
      </div>
    </div>
  );
};

FocusOnPeople.propTypes = {
  setRef: PropTypes.func.isRequired,
  isEnter: PropTypes.bool,
};

export default AnimationScrollSpy(FocusOnPeople);
