import React from 'react';
import OneStopToWork from 'components/home/OneStopToWork';
import Collaboration from 'components/home/Collaboration';
import Launch from 'components/features/Launch';
import FocusOnPeople from 'components/home/FocusOnPeople';
import Security from 'components/home/Security';
import styles from 'pages/index.module.scss';
import HomeVideo from 'components/home/HomeVideo';
import Header from '../components/shared/Header';
import GeneralHead from '../components/shared/GeneralHead/GeneralHead';
import ScrollTracking from '../components/shared/ScrollTracking';

const Home = () => (
  <div>
    <GeneralHead />
    <Header shouldMakeBgTransparentWhenScroll />
    <OneStopToWork />
    <HomeVideo />
    <FocusOnPeople />
    <Collaboration />
    <Security />
    <Launch top={<div className={styles.transition} />} />
  </div>
);

export default ScrollTracking(Home);
