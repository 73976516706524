import { useDeviceInfoContext } from 'components/shared/DeviceInfo';
import { useLocaleContext } from 'components/shared/Locale';
import React from 'react';
import Link from 'next/link';
import { ROUTE_FULL_PATH } from 'configs';
import styles from 'components/home/OneStopToWork.module.scss';
import AnimationScrollSpy from 'components/shared/AnimationScrollSpy';
import PropTypes from 'prop-types';
import cx from 'classnames';
import {
  FBPixelContactSalesTracking,
  FBPixelRegistrationTracking,
} from 'utils/fbq';
import LocaleImage from 'components/shared/LocaleImage';
import ImageBg from 'components/shared/ImageBg';
import ExternalLink from '../shared/ExternalLink';
import OneStopToWorkBg from './OneStopToWorkBg.svg';

const OneStopToWork = ({ setRef, isEnter }) => {
  const { locale } = useLocaleContext();
  const { isMobile } = useDeviceInfoContext();

  return (
    <div ref={setRef} className={styles.oneStopToWorkWrapper}>
      <div className={styles.oneStopToWorkWrapperBackground}>
        <OneStopToWorkBg />
      </div>

      <div className={styles.oneStopToWorkWrapperInnerBackground} />
      <ImageBg
        src="one-stop-for-work-inner.png"
        className={styles.oneStopToWorkWrapperInnerBackground}
      />

      <div
        className={cx(
          styles.oneStopToWorkWrapperInner,
          isEnter ? styles.oneStopToWorkWrapperInnerEnter : ''
        )}
        ref={setRef}
      >
        <div className={styles.content}>
          <h1 className={styles.title}>
            {locale('website_homepage_slogan_short')}
          </h1>

          <div className={styles.subtitle}>
            {locale('website_homepage_slogan_long')}
          </div>

          <div className={styles.btnSection}>
            {isMobile ? (
              <ExternalLink
                href={ROUTE_FULL_PATH.DOWNLOAD}
                className={styles.startBtn}
              >
                {locale('website_download_download')}
              </ExternalLink>
            ) : (
              <Link href={ROUTE_FULL_PATH.START}>
                <a
                  className={styles.startBtn}
                  onClick={() => {
                    FBPixelRegistrationTracking.enter();
                  }}
                >
                  {locale('website_get_started_free')}
                </a>
              </Link>
            )}

            <Link href={ROUTE_FULL_PATH.CONTACT_SALES}>
              <a
                className={styles.contactSaleBtn}
                onClick={() => {
                  FBPixelContactSalesTracking.enter();
                }}
              >
                {locale('website_contact_sales')}
              </a>
            </Link>
          </div>
        </div>

        <div
          className={cx(
            styles.homeBanner,
            isEnter ? styles.homeBannerEnter : ''
          )}
        >
          <div className={cx(styles.bannerImage)}>
            <LocaleImage
              src={
                isMobile
                  ? 'home-page-banner-mobile.png'
                  : 'home-page-banner.png'
              }
              alt={locale('website_one_stop_for_work_alt')}
            />
          </div>
          <div className={cx(styles.mobileSIllustrator)}>
            <LocaleImage src="home-page-s-illustrator.png" alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

OneStopToWork.propTypes = {
  setRef: PropTypes.func,
  isEnter: PropTypes.bool,
};

export default AnimationScrollSpy(OneStopToWork);
