import { useLocaleContext } from 'components/shared/Locale';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import AnimationScrollSpy from 'components/shared/AnimationScrollSpy';
import styles from 'components/home/HomeVideo.module.scss';
import cx from 'classnames';
import { LanguageEnum } from 'locale';
import PropTypes from 'prop-types';
import { GTagHomeVideoTracking } from 'utils/ga';
import videojs from 'video.js';
import 'video.js/dist/video-js.min.css';
import ImageBg from 'components/shared/ImageBg';
import { useDeviceInfoContext } from 'components/shared/DeviceInfo';
import { getMMSLink } from 'configs/env';

const defaultVideoURL =
  'https://hr.cdn.haiserve.com/seatalk-website/home/seatalk-intro-en-01.mp4';
const videoURLByLanguage = {
  [LanguageEnum.VI]:
    'https://hr.cdn.haiserve.com/seatalk-website/home/seatalk-intro-vn-01.mp4',
  [LanguageEnum.TH]:
    'https://hr.cdn.haiserve.com/seatalk-website/home/seatalk-intro-th-01.mp4',
  [LanguageEnum.ZH_CN]:
    'https://hr.cdn.haiserve.com/seatalk-website/home/seatalk-intro-tw-01.mp4',
  [LanguageEnum.ZH_HK]:
    'https://hr.cdn.haiserve.com/seatalk-website/home/seatalk-intro-tw-01.mp4',
  [LanguageEnum.ZH_TW]:
    'https://hr.cdn.haiserve.com/seatalk-website/home/seatalk-intro-tw-01.mp4',
  [LanguageEnum.ID]:
    'https://hr.cdn.haiserve.com/seatalk-website/home/seatalk-intro-id-01.mp4',
};

const HomeVideo = ({ setRef, isEnter }) => {
  const isStartVideoForTheFirstTime = useRef(false);
  const videoRef = useRef();
  const watchingDuration = useRef(0);
  const lastStart = useRef(0);
  const [isVideoPlaying, setIsVideoPlaying] = useState(false);
  const { language, locale } = useLocaleContext();
  const { isAcceptWebp } = useDeviceInfoContext();

  useEffect(() => {
    videojs(videoRef.current).ready(function () {
      const player = this;

      player.on('ended', () => {
        GTagHomeVideoTracking.finish();
      });
    });

    const onUnload = () => {
      const duration =
        watchingDuration.current +
        // lastStart.current !== 0 means that the video is not paused manually
        // before this component is unmount
        (lastStart.current !== 0
          ? new Date().getTime() - lastStart.current
          : 0);

      if (duration !== 0) {
        GTagHomeVideoTracking.duration(duration);
      }
    };

    // use "beforeunload" to handle case close tab or browser
    window.addEventListener('beforeunload', onUnload);

    return () => {
      window.removeEventListener('beforeunload', onUnload);
      onUnload();
    };
  }, []);

  const playVideo = useCallback(() => {
    setIsVideoPlaying(true);
    videoRef.current.play();
  }, []);

  return (
    <div ref={setRef} className={styles.homeVideoWrapper}>
      <ImageBg
        src="st-home-img-video-dots.jpg"
        className={styles.illustratorRight}
      />

      <div
        className={cx(
          styles.homeVideoWrapperInner,
          isEnter ? styles.homeVideoWrapperInnerEnter : ''
        )}
        ref={setRef}
      >
        <div className={styles.content}>
          <ImageBg
            src="st-home-img-video-dots.jpg"
            className={styles.illustratorCenter}
          />

          <h2 className={styles.title}>{locale('website_next_gen_collab')}</h2>

          <div className={styles.videoWrapper}>
            <div className={styles.topRightCorner} />
            <div className={styles.bottomLeftCorner} />
            {!isVideoPlaying && (
              <div className={styles.playButton} onClick={playVideo} />
            )}

            <div data-vjs-player>
              <video
                id="home-video"
                className="video-js"
                ref={videoRef}
                controls
                controlsList="nodownload"
                disablePictureInPicture
                preload="metadata"
                onPlay={() => {
                  setIsVideoPlaying(true);
                  if (!isStartVideoForTheFirstTime.current) {
                    GTagHomeVideoTracking.play();
                  }
                  isStartVideoForTheFirstTime.current = true;
                  lastStart.current = new Date().getTime();
                }}
                onPause={() => {
                  setIsVideoPlaying(false);
                  watchingDuration.current +=
                    new Date().getTime() - lastStart.current;
                  lastStart.current = 0;
                }}
                poster={`${getMMSLink()}/id-11134005-7r98t-lprnz0vod2ji4b.${
                  isAcceptWebp ? 'webp' : 'jpg'
                }`}
                data-setup="{}"
              >
                <source src={videoURLByLanguage[language] || defaultVideoURL} />
              </video>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

HomeVideo.propTypes = {
  setRef: PropTypes.func,
  isEnter: PropTypes.bool,
};

export default AnimationScrollSpy(HomeVideo);
